import 'react-toastify/dist/ReactToastify.css'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'react-toastify/dist/ReactToastify.css'
import { BrowserRouter as Router } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { useWindowSize } from 'hooks'
import { AppRoutes } from 'routes'
import { useUser } from 'store/useUser'
import { useEffect } from 'react'
// import "~slick-carousel/slick/slick.css";
// import "~slick-carousel/slick/slick-theme.css";

function App() {
  const size = useWindowSize().windowSize
  const { user } = useUser()
  async function get() {
    const data = await fetch('https://ipapi.co/json/', { method: 'GET' })
    const j = await data.json()
    console.log(j)
  }
  useEffect(() => {
    get()
  }, [])

  return (
    <div className="App">
      <ToastContainer />
      <Router>
        <AppRoutes size={size} user={user} />
      </Router>
      <ReactQueryDevtools />
    </div>
  )
}

export default App
