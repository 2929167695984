import { useQuery } from '@tanstack/react-query'
import { extraStaleTime } from 'config'
import { request } from 'utils/customAxiosInterceptor'

export function useGetVariants() {
  return useQuery({
    queryKey: ['variants'],
    queryFn: async () => {
      const { data } = await request.get('Variants')
      return data
    },
    staleTime: extraStaleTime,
  })
}
