import React, { memo } from 'react'
import { Skeleton } from '@mui/material'
import SliderCarousel from 'components/shared/SliderCarousel'
import { useAdsBanner } from 'hooks'

const MobileAdsBanner = memo(function MobileAdsBanner() {
  const { data, isLoading } = useAdsBanner()
  return (
    <div className="MobileAdsBanner">
      {isLoading ? (
        <Skeleton variant="rectangular" height={200} />
      ) : (
        <SliderCarousel data={data} disableAutoplay={true} disableLoop={true} />
      )}
    </div>
  )
})

export default MobileAdsBanner
