import Profile from 'components/shared/Profile'
function UserProfile() {
  return (
    <div className="profile container">
      <Profile />
    </div>
  )
}

export default UserProfile
