import { useQuery } from '@tanstack/react-query'
import { ReactQueryBigStaleTime } from 'config'
import { GalleryImagesHref } from 'services/advertisementServices'
import { request } from 'utils/customAxiosInterceptor'

export function useGalleryImages() {
  return useQuery({
    queryKey: ['gallery'],
    queryFn: async () => {
      const { data } = await request.get(GalleryImagesHref)
      return data
    },
    staleTime: ReactQueryBigStaleTime,
  })
}
