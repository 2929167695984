import { create } from 'zustand'
import { persist } from 'zustand/middleware'

export const useUser = create(
  persist(
    (set, get) => ({
      user: {},
      setUser: (newUser) =>
        set((state) => {
          return {
            ...state,
            user: newUser,
          }
        }),
    }),
    {
      name: '__user-storage', // unique name
    },
  ),
)
