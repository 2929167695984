import axios from 'axios'
import { toast } from 'react-toastify'
import { API_KEY, ORDER_VERIFICATION_TEMPLATE_Name } from 'services/userServices/KaveNegar'
import { request } from 'utils/customAxiosInterceptor'
import { toastConfig } from 'utils/toastConfig'

export const createUserOrder = (data) =>
  request.post('OrderList', {
    ...data,
    paid: false,
  })

export const proceedOrder = async (data, cart, user) => {
  let loading = true
  try {
    const response = await createUserOrder(data)
    if (response.status === 201 || response.status === 200) {
      const order = response.data.id
      const promises = []
      cart.cartItems.forEach((cartItem) => {
        promises.push(
          request.post('ItemOrderList', {
            product: cartItem.isVariant ? cartItem.product_variant : cartItem.id,
            user: user.id,
            variant: cartItem.isVariant ? cartItem.id : null,
            quantity: cartItem.cartAmount,
            order,
          }),
        )
      })
      const result = async () => {
        let status = false
        const ItemOrderList = await Promise.all(promises)
        ItemOrderList?.forEach((item) => {
          if (item?.status === 200) {
            status = true
          }
        })
        return status
      }
      const orderResult = await result()
      if (orderResult === true) {
        await sendOrderVerification(order)
        localStorage.removeItem('__cart-storage')
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = `http://backend.tismod.com/order/${order}/pay`
        link.title = 'پرداخت'
        link.click()

        // loading = false
        // window.location.href = '/'
      } else {
        toast.error('عملیات با خطا مواجه شد', toastConfig)
      }
    } else {
      toast.error(response.status, toastConfig)
      loading = false
    }
  } catch (error) {
    console.log(error)
    toast.error(
      `
    عملیات با خطا مواحه شد
    ${error.response.status}`,
      toastConfig,
    )
    loading = false
  }
  return { loading }
}
// 'http://rayansoft.co/order/payment-verify/?tc=858442437528087'
export const sendOrderVerification = async (token) => {
  try {
    const receptors = ['09186434070', '9122451398']
    const promises = []
    receptors.forEach((receptor) => {
      promises.push(
        axios.get(
          `https://api.kavenegar.com/v1/${API_KEY}/verify/lookup.json?receptor=${receptor}&token=${token}&template=${ORDER_VERIFICATION_TEMPLATE_Name}`,
        ),
      )
    })
    const response = await Promise.all(promises)
  } catch (error) {
    console.log(error)
  }
}
