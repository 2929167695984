import TismodDivider from 'components/UI/TismodDivider'
import React from 'react'
import ProProduct from '../ProProduct'
import ShoppableProducts from 'components/Mobile/ShppableProducts'

function NewProducts({ products, isLoading }) {
  return (
    <>
      <TismodDivider title={' جدیدترین‌ها '} />
      {/* <div className="new-products">
        {products?.map((product) => (
          <ProProduct product={product} isLoading={isLoading} key={product.id} />
        ))}
      </div> */}
      <div className="shop shop-new">
        <ShoppableProducts products={products} loading={isLoading} />
      </div>
    </>
  )
}

export default NewProducts
