import HeroBanner from 'components/Web/HeroBanner'
import React, { useMemo } from 'react'
import { useGetProducts } from 'hooks'
import RecommendedProducts from 'components/shared/RecommendedProducts'
import CenterCustomBanners from 'components/Mobile/CenterCustomBanners'
import NewProducts from '../NewProducts'
import ProProduct from '../ProProduct'

function Home() {
  const { data: products, isLoading } = useGetProducts()
  const newestProducts = useMemo(() => {
    return products?.sort((a, b) => b.id - a.id).slice(0, 10)
  }, [products])
  const bigProducts = useMemo(() => {
    return products?.sort((a, b) => b.id - a.id)?.slice(10, 13)
  }, [products])
  const recommendedProducts = useMemo(() => {
    return products?.filter((product) => product.recommendation)
  }, [products])
  return (
    <div>
      <HeroBanner />
      <div className="main-content">
        {newestProducts?.length && <NewProducts products={newestProducts} isLoading={isLoading} />}
        {bigProducts?.length && (
          <div className="new-products">
            {bigProducts?.map((product) => (
              <ProProduct product={product} isLoading={isLoading} key={product.id} />
            ))}
          </div>
        )}
        <CenterCustomBanners />
        {recommendedProducts?.length && <RecommendedProducts products={recommendedProducts} loading={isLoading} />}
      </div>
    </div>
  )
}

export default Home
