import 'styles/index.scss'
import { Grid } from '@mui/material'
import SliderBanner from 'components/Mobile/Banners/SliderBanner'
import { useMemo } from 'react'
import logoIcon from 'assets/logos/logo.png'
import CenterCustomBanners from 'components/Mobile/CenterCustomBanners'
import MobileAdsBanner from 'components/Mobile/Banners/MobileAdsBanner'
import TopCustomBanner from 'components/Mobile/TopCustomBanners/TopCustomBanners'
import NewProducts from 'components/shared/NewProducts'
import RecommendedProducts from 'components/shared/RecommendedProducts'
import { useGetProducts } from 'hooks'

function Home() {
  const { data: products, isLoading } = useGetProducts()
  const newestProducts = useMemo(() => {
    return products?.slice(Math.max(products.length - 10, 0))
  }, [products])

  const recommendedProducts = useMemo(() => {
    return products?.filter((product) => product.recommendation)
  }, [products])
  // if (isLoading || loading) {
  //   return (
  //     <div className="welcome-drop">
  //       <img src={logoIcon} alt="logo" width={200} height={200} />
  //       <AnimatedLoading />
  //     </div>
  //   )
  // }

  return (
    <div className="home page">
      <main>
        <Grid container>
          <Grid item xs={12} lg={6}>
            <TopCustomBanner />
          </Grid>
          <Grid item xs={12} sm={12} lg={6} className="main-banner">
            <SliderBanner />
          </Grid>
          <Grid item xs={12} sm={12} lg={6}>
            <CenterCustomBanners />
          </Grid>
          <Grid item xs={12} sm={12} lg={6} className="container ">
            {newestProducts?.length ? <NewProducts products={newestProducts} loading={isLoading} /> : null}
          </Grid>
          <Grid item xs={12} sm={12} lg={6} className="container ">
            <MobileAdsBanner />
          </Grid>
          {recommendedProducts?.length ? (
            <Grid item xs={12} sm={12} lg={6} className="container ">
              <RecommendedProducts products={recommendedProducts} loading={isLoading} />
            </Grid>
          ) : null}
        </Grid>
      </main>
    </div>
  )
}

export default Home
