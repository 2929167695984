import PricePrefixWhite from 'components/UI/Icons/PricePrefixWhite'
import React from 'react'
import { Link } from 'react-router-dom'
import { navigateTo } from 'routes'
import { convertToPersian } from 'utils/convertNumbers'

function ProProduct({ product }) {
  return (
    <Link to={navigateTo.productDetails(product.id, product.name)} className="pro-product">
      <div className="pro-product__image">
        <img src={product.image} alt={product.name} width={411} height={411} />
      </div>
      <div className="pro-product__caption">
        <div className="pro-product__title">{product.name}</div>
      </div>
      <div className="product__price-wrapper">
        <div
          className={`product__price-price  ${product?.variants?.length ? 'product__price-wrapper--with-option' : ''}`}
        >
          <p className={'product__price ' + (product?.discount ? 'product__price--discounted' : '')}>
            {product?.discount ? convertToPersian(product?.total_price) : convertToPersian(product?.unit_price)}{' '}
          </p>
          <PricePrefixWhite />
        </div>
        {product?.discount ? (
          <s className="product__price product__price-discount">{convertToPersian(product?.unit_price)}</s>
        ) : null}
      </div>
    </Link>
  )
}

export default ProProduct
