import React, { useState, useEffect } from 'react'
import { Skeleton } from '@mui/material'
import { convertToPersian } from 'utils/convertNumbers'
import AddIcon from 'components/UI/Icons/AddIcon'
import MinusIcon from 'components/UI/Icons/MinusIcon'
import { toast } from 'react-toastify'
import { toastConfig } from 'utils/toastConfig'
import { useCart } from 'store'

function ProductPriceArea({ response, loading, selectedSize, selectedColor }) {
  const [amount, setAmount] = useState(0)
  const { addToCart, cart, removeFromCart } = useCart()
  useEffect(() => {
    if (cart) {
      const cartItemAmount = cart.find((item) =>
        item.isVariant
          ? item.id === response.id && item.product_variant === response.product_variant
          : item.id === response.id,
      )?.cartAmount
      setAmount(cartItemAmount || 0)
    }
  }, [cart, response])
  const hasError = () => {
    if ((response?.colors?.length || response?.color_variant) && !selectedColor) {
      toast.error('لطفا رنگ محصول را انتخاب کنید', toastConfig)
      return true
    }
    if ((response?.sizes?.length || response?.size_variant) && !selectedSize) {
      toast.error('لطفا سایز محصول را انتخاب کنید', toastConfig)
      return true
    }
    return false
  }
  const handleProductAddToCart = (method) => {
    if (hasError()) return

    if (method === 'add') {
      if (amount === response?.amount) {
        toast.error('موجودی محصول کافی نیست', toastConfig)

        return
      } else {
        const quantity = amount + 1
        setAmount(quantity)
        addToCart(response
          , {
          color: selectedColor,
          size: selectedSize,
          cartAmount: quantity,
          selectedColor,
          selectedSize,
        })
      }
    } else {
      const quantity = amount - 1
      if (quantity < 0) return
      setAmount(quantity)
      if (quantity === 0) {
        removeFromCart(response)
        return
      }
      setAmount(quantity)
      addToCart(response, {
        color: selectedSize,
        size: selectedColor,
        cartAmount: quantity,
        selectedColor,
        selectedSize,
      })
    }
  }
  return (
    <>
      <div className="product__info__price">
        {response?.amount && (response?.amount > 0 || response?.available === true) ? (
          loading ? (
            <Skeleton variant="text" width={200} animation="wave" />
          ) : (
            <>
              <div>
                <p className={'product-price ' + (response?.discount ? 'discounted' : '')}>
                  {response?.discount
                    ? convertToPersian(response?.total_price)
                    : convertToPersian(response?.unit_price)}{' '}
                  تومان
                </p>
                {response?.discount ? (
                  <s className="discount-price">{convertToPersian(response?.unit_price)} تومان</s>
                ) : null}
              </div>

              <div className="product__quantity">
                <span className="product__quantity__add" onClick={() => handleProductAddToCart('add')}>
                  <AddIcon />
                </span>
                <span className="product__quantity__number">{amount}</span>
                <span className="product__quantity__remove" onClick={() => handleProductAddToCart('delete')}>
                  <MinusIcon />
                </span>
              </div>
            </>
          )
        ) : (
          <p>متاسفانه محصول موجود نیست</p>
        )}
      </div>
    </>
  )
}

export default ProductPriceArea
