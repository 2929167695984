import { navigateTo } from 'routes'
import { Link } from 'react-router-dom'
import { Autoplay, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import NextArrow from 'components/UI/Icons/nextArrow'

function SliderCarousel({ data, disableAutoplay, disableLoop }) {
  console.log(data);
  return (
    <Swiper
      spaceBetween={12}
      allowTouchMove={true}
      centeredSlides={true}
      loop={!disableLoop}
      modules={[Pagination, Autoplay]}
      pagination={{
        dynamicBullets: true,
        clickable: true,
      }}
      autoplay={
        data.length > 1
          ? {
              delay: 3000,
              disableOnInteraction: false,
            }
          : false
      }
    >
      {data?.map((item) => {
        const productName = item.ProductName || item.name
        return (
          <SwiperSlide key={item.id} className="carousel-wrapper">
            <Link to={navigateTo.productDetails(item.product, productName)} key={item.id}>
              <img
                src={item.image || item.banner}
                alt={item.title}
                className="carousel-image"
                width={300}
                height={130}
              />
              <div className="herobanner-btn">
                <span className="herobanner-btn__icon">
                  <NextArrow />
                </span>
                <span>نمایش</span>
              </div>
            </Link>
          </SwiperSlide>
        )
      })}
    </Swiper>
  )
}

export default SliderCarousel
