import { FormControl, NativeSelect } from '@mui/material'
import UserIcon from 'components/UI/Icons/UserIcon'
import ShoppingBagIcon from 'components/UI/Icons/shoppingBagIcon'
import SearchBar from 'components/shared/forms/searchbar'
import { useGetCategories } from 'hooks'
import React, { useMemo } from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { ROUTE_PATHS, navigateTo } from 'routes'
import { BOYS_CATEGORY_ID, GIRLS_CATEGORY_ID } from 'utils/constants/parentCategoryIds'
import logo from 'assets/logos/logo.png'
import { useUser } from 'store'

const styles = {
  color: 'white',
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'white',
  },
  '& .MuiSvgIcon-root': {
    color: 'white',
  },
  option: {
    color: 'black',
  },
}
function DesktopHeader() {
  const { data: categories, isLoading } = useGetCategories()
  const [boysCategory, setBoysCategory] = useState()
  const [girlsCategory, setGirlsCategory] = useState()
  useMemo(() => {
    setBoysCategory(categories?.filter((category) => category.sub_category?.toString() === BOYS_CATEGORY_ID))
  }, [categories])
  const { user } = useUser()
  useMemo(() => {
    setGirlsCategory(
      categories?.filter((category) => category.sub_category && category.sub_category.toString() === GIRLS_CATEGORY_ID),
    )
  }, [categories])
  const handleChange = (e) => {
    const id = e.target.value.split('-')[0]
    const name = e.target.value.split('-')[1]
    window.location.href = navigateTo.ProductCategory(id, name)
  }
  return (
    <div className="header-bg">
      <div className="header__menu">
        <ul>
          <li className="header__menu__item">
            پسرانه
            <span className="header__menu__item__icon">
              <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M10.9999 1.16994C10.8126 0.983692 10.5591 0.87915 10.2949 0.87915C10.0308 0.87915 9.77731 0.983692 9.58995 1.16994L5.99995 4.70994L2.45995 1.16994C2.27259 0.983692 2.01913 0.87915 1.75495 0.87915C1.49076 0.87915 1.23731 0.983692 1.04995 1.16994C0.95622 1.26291 0.881826 1.37351 0.831057 1.49537C0.780288 1.61723 0.75415 1.74793 0.75415 1.87994C0.75415 2.01195 0.780288 2.14266 0.831057 2.26452C0.881826 2.38638 0.95622 2.49698 1.04995 2.58994L5.28995 6.82994C5.38291 6.92367 5.49351 6.99806 5.61537 7.04883C5.73723 7.0996 5.86794 7.12574 5.99995 7.12574C6.13196 7.12574 6.26267 7.0996 6.38453 7.04883C6.50638 6.99806 6.61699 6.92367 6.70995 6.82994L10.9999 2.58994C11.0937 2.49698 11.1681 2.38638 11.2188 2.26452C11.2696 2.14266 11.2957 2.01195 11.2957 1.87994C11.2957 1.74793 11.2696 1.61723 11.2188 1.49537C11.1681 1.37351 11.0937 1.26291 10.9999 1.16994Z"
                  fill="white"
                />
              </svg>
            </span>
            <div className="header__menu__item__sublist">
              {boysCategory?.map((cat) => (
                <div key={cat.id} className="header__menu__item__sublist__item">
                  <Link
                    className="header__menu__item__sublist__item__link"
                    to={navigateTo.ProductCategory(cat.id, cat.name)}
                  >
                    {cat.name}
                  </Link>
                </div>
              ))}
            </div>
          </li>
          <li className="header__menu__item">
            دخترانه
            <span className="header__menu__item__icon">
              <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M10.9999 1.16994C10.8126 0.983692 10.5591 0.87915 10.2949 0.87915C10.0308 0.87915 9.77731 0.983692 9.58995 1.16994L5.99995 4.70994L2.45995 1.16994C2.27259 0.983692 2.01913 0.87915 1.75495 0.87915C1.49076 0.87915 1.23731 0.983692 1.04995 1.16994C0.95622 1.26291 0.881826 1.37351 0.831057 1.49537C0.780288 1.61723 0.75415 1.74793 0.75415 1.87994C0.75415 2.01195 0.780288 2.14266 0.831057 2.26452C0.881826 2.38638 0.95622 2.49698 1.04995 2.58994L5.28995 6.82994C5.38291 6.92367 5.49351 6.99806 5.61537 7.04883C5.73723 7.0996 5.86794 7.12574 5.99995 7.12574C6.13196 7.12574 6.26267 7.0996 6.38453 7.04883C6.50638 6.99806 6.61699 6.92367 6.70995 6.82994L10.9999 2.58994C11.0937 2.49698 11.1681 2.38638 11.2188 2.26452C11.2696 2.14266 11.2957 2.01195 11.2957 1.87994C11.2957 1.74793 11.2696 1.61723 11.2188 1.49537C11.1681 1.37351 11.0937 1.26291 10.9999 1.16994Z"
                  fill="white"
                />
              </svg>
            </span>
            <div className="header__menu__item__sublist">
              {girlsCategory?.map((cat) => (
                <div key={cat.id} className="header__menu__item__sublist__item">
                  <Link
                    className="header__menu__item__sublist__item__link"
                    to={navigateTo.ProductCategory(cat.id, cat.name)}
                  >
                    {cat.name}
                  </Link>
                </div>
              ))}
            </div>
          </li>
          <li className="header__menu__item">
            <Link to={'/products/28/ست-اسپرت'}>ست اسپرت</Link>
          </li>
          <li className="header__menu__item">
            <Link to={ROUTE_PATHS.contactUs}>تماس با ما</Link>
          </li>
        </ul>
      </div>
      <div className="header__search">
        <SearchBar />
      </div>
      <div className="header__btns">
        <span>
          <Link to={ROUTE_PATHS.cart}>
            <ShoppingBagIcon color={'#ffff'} />
          </Link>
        </span>
        <span>
          <Link to={user?.id ? ROUTE_PATHS.profile : ROUTE_PATHS.login}>
            <UserIcon color={'#ffff'} />
          </Link>
        </span>
        <span>
          <Link to={ROUTE_PATHS.home}>
            <img src={logo} alt="logo" width={32} height={30} className="desktop-logo" />
          </Link>
        </span>
      </div>
    </div>
  )
}

export default DesktopHeader
