import React from 'react'
import SliderCarousel from 'components/shared/SliderCarousel'
import Skeleton from '@mui/material/Skeleton'
import { useGalleryImages } from 'hooks'
const SliderBanner = React.memo(function SliderBanner() {
  const { data, isLoading } = useGalleryImages()
  return isLoading ? (
    <Skeleton
      variant="rectangular"
      height={190}
      animation="wave"
      sx={{
        margin: '16px',
        marginTop: '0',
      }}
    />
  ) : (
    <div className="main-slider ">
      <SliderCarousel data={data} />
    </div>
  )
})

export default SliderBanner
