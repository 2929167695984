/* eslint-disable react/no-unescaped-entities */
import { useCallback, useEffect, useState } from 'react'
import ProductDetail from 'components/shared/ProductPage/ProductDetail'
import ProductImage from 'components/shared/ProductPage/ProductImage'
import { useParams } from 'react-router-dom'
import NotFound from 'containers/NotFound'
import VoteHeart from 'components/UI/VoteHeart'
import ShareIcon from 'components/UI/Icons/ShareIcon'
import ProductDescription from 'components/shared/ProductPage/ProductDescription'
import { getProductsOtherImages } from 'services/productManagementServices'
import { Skeleton } from '@mui/material'
import { useGetVariants, useProduct, useProductVariantChange } from 'hooks'
import { setProduct } from 'utils/setProduct/setProduct'
import SizePicker from 'components/shared/ProductPage/productUserActions/SizePicker'
import ColorPicker from 'components/shared/ProductPage/productUserActions/ColorPicker'
import { useCart } from 'store'
import { convertToPersian } from 'utils/convertNumbers'
import { request } from 'utils/customAxiosInterceptor'
const styles = {
  marginTop: '16px',
  borderRadius: '16px',
}

function Product() {
  const { productId } = useParams()
  const [selectedColor, setSelectedColor] = useState(null)
  const [selectedSize, setSelectedSize] = useState(null)
  const [response, setResponse] = useState({})
  const [loading, setLoading] = useState(true)
  const { data, isLoading } = useProduct(productId)

  const getProductData = async () => {
    // if(response.id)return
    const { data : variants } = await request.get('Variants')
    const productVariant = variants?.filter((item) => parseInt(item.product_variant) === parseInt(productId)) ?? null
    const productWithVariant = setProduct(data, productVariant)
    setResponse(productWithVariant)
    setLoading(false)
  }
  useEffect(() => {
    if (data) {
      getProductData()
    }
  }, [data])

  const { product, colorList, sizeList, variantError } = useProductVariantChange(
    response,
    selectedColor,
    selectedSize,
    setSelectedColor,
    setSelectedSize,
  )
  const [productImages, setProductImages] = useState([])
  const { addToCart } = useCart()

  const getProductImages = async () => {
    try {
      const allImages = await getProductsOtherImages()
      const productImages = allImages.data.filter((image) => image.product === response?.id).map((image) => image.image)
      if (productImages.length) {
        setProductImages(productImages)
      }
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getProductImages()
  }, [response])
  return (
    <div className="desktop-product">
      {loading || isLoading ? (
        <div>
          <Skeleton variant="rectangle" height={500} sx={styles} />
        </div>
      ) : data ? (
        <>
          {product && (
            <>
              <div className="product-main">
                <div className="product__images__wrapper">
                  {productImages && (
                    <ProductImage product={product} images={productImages} loading={isLoading || isLoading} />
                  )}
                </div>
                <div className="product__secondary">
                  <div className="product__details__wrapper">
                    <div>
                      <div className="product__title">{product.name}</div>
                      <div className="product__options">
                        <div className="product__size">
                          {sizeList?.length ? (
                            <SizePicker
                              setSelectedSize={setSelectedSize}
                              selectedSize={selectedSize}
                              sizeList={sizeList}
                            />
                          ) : null}
                        </div>
                        <div className="product__color">
                          {colorList?.length ? (
                            <ColorPicker
                              setSelectedColor={setSelectedColor}
                              selectedColor={selectedColor}
                              colorList={colorList}
                            />
                          ) : null}
                        </div>
                      </div>
                      <div className="product__description">
                        {' '}
                        {product?.information ? <ProductDescription product={product} /> : null}
                      </div>
                    </div>
                    <div className="product__buy-section">
                      <div className="product__buy-section__price">
                        <p className={'product__price ' + (product?.discount ? 'product__price--discounted' : '')}>
                          {product?.discount
                            ? convertToPersian(product?.total_price)
                            : convertToPersian(product?.unit_price)}{' '}
                        </p>
                        تومان
                        {product?.discount ? (
                          <s className="product__price product__price-discount">
                            {convertToPersian(product?.unit_price)}
                          </s>
                        ) : null}
                      </div>
                      <div className="product__buy-section__button">
                        <button
                          disabled={!product.available || product.amount <= 0}
                          onClick={() => {
                            addToCart(product, {
                              selectedColor,
                              selectedSize,
                              cartAmount: 1,
                            })
                          }}
                        >
                          {product.available && product.amount > 0 ? 'افزودن به  سبد ' : 'ناموجود'}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="product__warning">توضیحات: امکان برگشت کالا به هر دلیلی وجود ندارد .</div>
                </div>
              </div>
            </>
          )}
        </>
      ) : (
        <NotFound />
      )}
    </div>
  )
}

export default Product
