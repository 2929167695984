import { Link } from 'react-router-dom'
import { navigateTo } from 'routes'
import { convertToPersian } from 'utils/convertNumbers'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Box from '@mui/material/Box'
import AddIcon from 'components/UI/Icons/AddIcon'
import DeleteIcon from 'components/UI/Icons/DeleteIcon'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { toastConfig } from 'utils/toastConfig'
import RegularPricePrefix from 'components/UI/Icons/PricePrefix/RegularPricePrefix'
import { useCart } from 'store'

const styles = {
  display: 'flex',
  background: ' rgba(255, 255, 255, 0.8)',
  borderRadius: '8px',
  marginBottom: '16px',
  width: '100%',
  '@media (max-width: 900px)': {
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 10px 18px rgba(0, 0, 0, 0.25)',
    maxHeight: '162px',
    padding: '7px',
  },
}
function ProductCard({ product, isInCart }) {
  const { addToCart, removeFromCart } = useCart()

  const [amount, setAmount] = useState(product.cartAmount || null)
  const handleProductAddToCart = (method) => {
    const color = product.color ? product.color.label : null
    const size = product.size ? product.size.label : null
    if (method === 'add') {
      if (amount === product?.amount) {
        toast.error('موجودی محصول کافی نیست', toastConfig)
        return
      } else {
        const quantity = amount + 1
        setAmount(quantity)
        addToCart(product, {
          color,
          size,
          cartAmount: quantity,
        })
      }
    } else {
      removeFromCart(product)
    }
  }

  return (
    <>
      {product && (
        <Card sx={styles} to={navigateTo.productDetails(product?.id, product?.name)} className="product__card">
          <CardContent
            sx={{
              display: 'grid',
              width: '100%',
              padding: '7px',
              paddingBottom: '0 !important',
              gridTemplateColumns: 'auto 2fr',
              '@media (min-width: 900px)': {
                paddingTop: '0',
                paddingRight: '0',
                paddingLeft: '24px',
              },
            }}
          >
            <Box sx={{ padding: 0 }}>
              <Link to={navigateTo.productDetails(product.productId || product?.id, product?.name)}>
                <CardMedia
                  component="img"
                  sx={{
                    width: '116px',
                    height: '113px',
                    border: '0.5px solid #E0E0E0',
                    '@media (min-width: 900px)': {
                      width: '300px',
                      height: '329px',
                    },
                  }}
                  image={product.image}
                  alt={product?.name}
                />
              </Link>

              {isInCart ? (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '6px',
                    '@media (min-width: 900px)': {
                      display: 'none',
                    },
                  }}
                >
                  <span className="cart__item__icon" onClick={() => handleProductAddToCart('add')}>
                    <AddIcon />
                  </span>
                  <span className="cart__item__amount">{convertToPersian(amount)}</span>
                  <span className="cart__item__icon" onClick={() => handleProductAddToCart('delete')}>
                    <DeleteIcon />
                  </span>
                </Box>
              ) : null}
            </Box>
            <div>
              <Box
                className="thumbnail__caption"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                }}
              >
                <div>
                  <Link
                    to={navigateTo.productDetails(product.productId || product?.id, product?.name)}
                    className="product__title"
                  >
                    {product?.name}
                  </Link>
                  {isInCart ? (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        marginTop: '6px',
                        '@media (max-width: 900px)': {
                          display: 'none',
                        },
                        backgroundColor: '#F2F2F2',
                        maxWidth: 'max-content',
                        borderRadius: '8px',
                      }}
                      onClick={(e) => {
                        e.stopPropagation() // <-- prevent event from bubbling up
                      }}
                    >
                      <span className="cart__item__icon" onClick={() => handleProductAddToCart('add')}>
                        <AddIcon />
                      </span>
                      <span className="cart__item__amount">{convertToPersian(amount)}</span>
                      <span className="cart__item__icon" onClick={() => handleProductAddToCart('delete')}>
                        <DeleteIcon />
                      </span>
                    </Box>
                  ) : null}
                </div>

                <div className="product__price-wrapper">
                  <div
                    className={`product__price-price  ${
                      product?.variants?.length ? 'product__price-wrapper--with-option' : ''
                    }`}
                  >
                    <p className={'product__price ' + (product?.discount ? 'product__price--discounted' : '')}>
                      {product?.discount
                        ? convertToPersian(product?.total_price)
                        : convertToPersian(product?.unit_price)}{' '}
                    </p>
                    <RegularPricePrefix />
                  </div>
                  {product?.discount ? (
                    <s className="product__price product__price-discount">{convertToPersian(product?.unit_price)}</s>
                  ) : null}
                </div>
              </Box>
            </div>
          </CardContent>
        </Card>
      )}
    </>
  )
}

export default ProductCard
