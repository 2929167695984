import { handlizeName } from 'utils/handlizeName'

export const ROUTE_PATHS = {
  home: '/',
  product: '/product/:productId/:productHandle',
  cart: '/cart',
  login: '/login',
  checkout: '/checkout',
  discounted: '/discounted',
  profile: '/profile',
  search: '/search',
  category: '/category-list/:categoryId',
  categories: '/category-list',
  contactUs: '/contact-us',
  addresses: '/addresses',
  productCategory: '/products/:productCategoryId/:productCategoryName',
  products: '/products',
  hashProductList: '/products/:hash',
  addAddress: '/addresses/add',
  orders: '/orders/:userId/:status',
}
export const navigateTo = {
  categoryDetails: (categoryId) => `${ROUTE_PATHS.category}`.replace(':categoryId', categoryId),
  productDetails: (productId, productHandle) =>
    `${ROUTE_PATHS.product}`.replace(':productId', productId).replace(':productHandle', handlizeName(productHandle)),
  orders: (userId, status) => `${ROUTE_PATHS.orders}`.replace(':userId', userId).replace(':status', status),
  ProductCategory: (categoryId, categoryName) =>
    `${ROUTE_PATHS.productCategory}`
      .replace(':productCategoryId', categoryId)
      .replace(':productCategoryName', handlizeName(categoryName)),
  ProductListByHashtag: (hashtag) => `${ROUTE_PATHS.hashProductList}`.replace(':hash', hashtag),
}
