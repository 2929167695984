
export const setProduct = (data, productVariant) => {
//if we find out the product has variants we need to get the variants

  if (productVariant) {
    return {
      ...data,
      colors: [
        ...new Set(productVariant.filter((item) => item.color_variant !== null)?.map((item) => item.color_variant)),
      ],
      sizes: [
        ...new Set(productVariant.filter((item) => item.size_variant !== null)?.map((item) => item.size_variant)),
      ],
      discount_price: data.discount ? Math.round(data.unit_price - data.total_price) | 0 : 0,
      variants: [
        ...productVariant.map((item) => ({
          ...item,
          isVariant: true,
          totalAmount: data.amount,
          color: item.color_variant,
          size: item.size_variant,
          colors: [
            ...new Set(productVariant.filter((item) => item.color_variant !== null)?.map((item) => item.color_variant)),
          ],
          sizes: [
            ...new Set(productVariant.filter((item) => item.size_variant !== null)?.map((item) => item.size_variant)),
          ],
          discount_price: item.discount ? Math.floor(item.unit_price - item.total_price) | 0 : 0,
          information: data.information,
          image: data.image,
        })),
      ],
    }
  } else {
    return {
      ...data,
    }
  }
}
