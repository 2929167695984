import { Skeleton } from '@mui/material'
import { useGetCategories, useGetCustomBanners } from 'hooks'
import React, { useCallback } from 'react'
import { Link } from 'react-router-dom'
import { ROUTE_PATHS, navigateTo } from 'routes'
import plusImage from 'assets/icons/plus-rounded.png'
import ProgressiveImage from 'react-progressive-graceful-image'
function CollectionsTopBanner() {
  // this is being named incorrectly from backend banners are actually the list of links appearing in the main banner
  const { data: banners, isLoading } = useGetCustomBanners()

  const { data: categories, isLoading: loadingCategories } = useGetCategories()
  const bannerLink = useCallback(
    (banner) => {
      switch (banner?.type) {
        case 'Category':
          const categoryName = categories.find((cat) => cat.id === banner.category)?.name
          return navigateTo.ProductCategory(banner.category, categoryName)
        case 'Product':
          return navigateTo.productDetails(banner.product)
        case 'Hashtag':
          return navigateTo.ProductListByHashtag(banner.hashtag_name)
        default:
          break
      }
    },
    [categories],
  )
  return (
    <div className="desktop-categories">
      {isLoading || loadingCategories ? (
        new Array(5).fill(true).map((item, index) => (
          <div key={index}>
            <Skeleton
              variant="circular"
              height={180}
              width={180}
              animation="wave"
              sx={{ marginLeft: '10px', borderRadius: '24px' }}
            />
          </div>
        ))
      ) : (
        <>
          {banners
            ?.filter((banner) => banner.placement === 'Top')
            .map((banner, index) => {
              if (index < 5) {
                return (
                  <div
                    key={index}
                    onClick={() => {
                      if (banner.type === 'Url') {
                        window.open(banner.hypertext, '_blank')
                      }
                    }}
                  >
                    <Link to={bannerLink(banner)}>
                      <div className="desktop-categories__item ">
                        <ProgressiveImage src={banner.top_banner}>
                          {(src, loading) => (
                            <img
                              className={`image${loading ? ' loading' : ' loaded'}`}
                              src={src}
                              alt={banner.title}
                              width="177"
                              height="114"
                            />
                          )}
                        </ProgressiveImage>
                        {/* <img src={banner.top_banner} alt={banner.name} loading="lazy" /> */}
                        <div>{banner.name}</div>
                      </div>
                    </Link>
                  </div>
                )
              }
              return <></>
            })}
          <div>
            <Link to={ROUTE_PATHS.categories}>
              <div className="desktop-categories__item">
                <ProgressiveImage src={plusImage}>
                  {(src, loading) => (
                    <img
                      className={`image${loading ? ' loading' : ' loaded'}`}
                      src={src}
                      alt="بیشتر"
                      width="177"
                      height="114"
                    />
                  )}
                </ProgressiveImage>
                <div>بیشتر</div>
              </div>
            </Link>
          </div>
        </>
      )}
    </div>
  )
}

export default CollectionsTopBanner
