import React, { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { getCityList, getProvinceList } from 'services/cityProvinceServices'
import { ErrorMessage } from '@hookform/error-message'
import { request } from 'utils/customAxiosInterceptor'
import CustomAutoComplete from 'components/UI/CustomAutoComplete'
import { usePrevious, useProvinceList } from 'hooks'
import { AddressFields } from './AddressFields'
import { toast } from 'react-toastify'
import { toastConfig } from 'utils/toastConfig'
import AnimatedLoading from 'components/UI/AnimatedLoading'
function AddressForm({ setOpenForm, setSelectedAddress, user, selectedAddress, mode, location, getAddresses }) {
  const [cityList, setCityList] = React.useState([])
  const [provinceError, setProvinceError] = React.useState(null)
  const [cityError, setCityError] = React.useState(null)
  const [citiesLoading, setCitiesLoading] = React.useState(false)
  const [newAddress, setNewAddress] = React.useState(selectedAddress || [])
  const [loadingAdd, setLoadingAdd] = React.useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const { data: provinceList, isLoading } = useProvinceList()
  const setProvinceOptions = useCallback(async () => {
    if (selectedAddress) {
      const currentProvince = provinceList.find((province) => province.label === selectedAddress.province)
      if (!currentProvince) return
      setNewAddress({
        ...newAddress,
        province: currentProvince,
      })
    }
  }, [newAddress, provinceList])
  const getProvinceCities = useCallback(async () => {
    setCitiesLoading(true)
    try {
      const response = await getCityList(newAddress?.province?.label)
      setCityList(
        response.data.cities.map((province) => {
          return {
            value: province.id,
            label: province.name,
          }
        }),
      )
      if (selectedAddress) {
        const currentCity = response.data.cities.find((city) => city.name === selectedAddress.city)
        if (!currentCity) return
        setNewAddress({
          ...newAddress,
          city: {
            value: currentCity.id,
            label: currentCity.name,
          },
        })
      }
      setCitiesLoading(false)
    } catch (error) {
      console.log(error)
    }
  }, [newAddress, selectedAddress])
  const submitUserAddress = async (data) => {
    if (cityError || provinceError) return

    const userData = {
      f_name: data.f_name,
      l_name: data.l_name,
      address: data.address,
      code: newAddress.code,
      user: user.id,
      receiver_phone: data.receiver_phone,
      city: newAddress.city.label,
      province: newAddress.province.label,
    }
    await AddAddress(userData)
  }
  const AddAddress = async (userData) => {
    setLoadingAdd(true)
    try {
      const response =
        mode === 'add'
          ? await request.post('Addresslist', userData)
          : await request.patch(`AddresslistEdit/${selectedAddress.id}/`, {
              ...userData,
              id: selectedAddress.id,
            })
      if (response && location === 'checkout') {
        setSelectedAddress(response.data)
      }
      if (response && location === 'profile') {
        await getAddresses()
      }
      if (response) {
        setLoadingAdd(false)
        setOpenForm(false)
      }
    } catch (error) {
      console.log(error)
      toast.error('متاسفانه مشکلی پیش آمده لطغا پس از بررسی دوباره اطلاعات، دوباره امتحان کنید', toastConfig)
      setLoadingAdd(false)
    }
  }
  useEffect(() => {
    if (provinceList) setProvinceOptions()
  }, [provinceList])
  const prevProvince = usePrevious(newAddress.province)
  useEffect(() => {
    if (prevProvince?.value !== newAddress?.province?.value) getProvinceCities()
  }, [getProvinceCities, newAddress, prevProvince])

  return (
    <div className="checkout__form">
      <div className={`checkout__form__content ${loadingAdd ? 'is-loading' : ''}`}>
        {isLoading ? (
          <div
            style={{
              height: '80vh',
            }}
          >
            <AnimatedLoading isFullPage={true} />
          </div>
        ) : (
          <form onSubmit={handleSubmit((data) => submitUserAddress(data))} autoComplete="is-off">
            {AddressFields.map((field, index) => {
              return (
                <div className="checkout__form__content__item" key={index}>
                  <div className="label">{field.label}</div>
                  {field.name === 'province' ? (
                    <>
                      <CustomAutoComplete
                        isLoading={isLoading}
                        options={provinceList}
                        onChange={(e, value) => {
                          setNewAddress({
                            ...newAddress,
                            city: null,
                            province: value,
                          })
                          setProvinceError(null)
                        }}
                        value={newAddress.province}
                      />
                      {provinceError && <p className="error-message">{provinceError}</p>}
                    </>
                  ) : field.name === 'city' ? (
                    <>
                      <CustomAutoComplete
                        isLoading={citiesLoading}
                        options={cityList}
                        onChange={(e, value) => {
                          setNewAddress({
                            ...newAddress,
                            city: value,
                          })
                          setCityError(null)
                        }}
                        value={newAddress.city}
                      />
                      {cityError && <p className="error-message">{cityError}</p>}
                    </>
                  ) : field.name === 'address' ? (
                    <textarea
                      {...register('address', { required: 'این فیلد اجباریست' })}
                      id="address"
                      value={newAddress.address || ''}
                      onChange={(e) =>
                        setNewAddress({
                          ...newAddress,
                          address: e.target.value,
                        })
                      }
                      autoComplete="is-off"
                    />
                  ) : (
                    <>
                      <input
                        {...register(field.name, { required: 'این فیلد اجباریست' })}
                        id={newAddress[field.name]}
                        value={newAddress[field.name] || ''}
                        name={field.name}
                        onChange={(e) => {
                          newAddress[e.target.name] = e.target.value
                          setNewAddress({
                            ...newAddress,
                            [e.target.name]: e.target.value,
                          })
                        }}
                        autoComplete="is-off"
                      />
                      <p className="error-message">
                        <ErrorMessage errors={errors} name={field.name} />
                      </p>
                    </>
                  )}
                </div>
              )
            })}

            <div className="checkout__form__content__buttons">
              <button
                onClick={() => {
                  if (!newAddress.province.value) {
                    setProvinceError('لطفا استان را انتخاب کنید')
                  }

                  if (!newAddress.city.value) {
                    setCityError('لطفا شهر را انتخاب کنید')
                  }
                }}
                type="submit"
                className="checkout__form__content__submit-btn"
                disabled={loadingAdd}
              >
                ثبت اطلاعات
              </button>
              <button
                className="checkout__form__content__cancel-btn"
                onClick={() => {
                  setOpenForm(false)
                  setNewAddress(selectedAddress)
                }}
              >
                انصراف
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  )
}

export default AddressForm
