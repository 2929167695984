import React from 'react'
import logo from 'assets/logos/footer-logo.png'
import instagram from 'assets/logos/instagram.svg'
import Enamad from 'assets/images/enamad.png'
import { companyInfo } from 'ShopInformation'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import TelegramIcon from '@mui/icons-material/Telegram'
import InstagramIcon from '@mui/icons-material/Instagram'
function Footer({ size }) {
  return (
    <div className="footer none-vertical-space">
      <div className="footer__main">
        <div className="footer__main__top">
          <div className="footer__text">
            <b> درباره پوشاک تیس‌مد</b>
            <br />
            <p>
              مجموعه تیس‌مد همراه با کادری مجرب و حرفه ای ، به عنوان تولید کننده محصولات بچه گانه فعالیت خود میکند.
              مجموعه تیس‌مد همواره ارائه محصولات با بیشترین کیفیت و ارزان ترین قیمت ها با سود بسیار پایین را سرلوحه خود
              قرار داده است که با ارسال به سراسر کشور با بیشترین سرعت ممکن در خدمت شما هم میهنان عزیز خواهد بود.
            </p>
          </div>
          <div className="footer__namad">
            <a
              referrerpolicy="origin"
              target="_blank"
              href="https://trustseal.enamad.ir/?id=504164&Code=MBeO6GnIMAETprhENcV7Rszyl2vzRTRE"
            >
              <img
                referrerpolicy="origin"
                src="https://trustseal.enamad.ir/logo.aspx?id=504164&Code=MBeO6GnIMAETprhENcV7Rszyl2vzRTRE"
                alt=""
                style={{ cursor: 'pointer' }}
                code="MBeO6GnIMAETprhENcV7Rszyl2vzRTRE"
              />
            </a>
          </div>
        </div>
        <div className="footer__main__bottom">
          <div className="footer__media-links">
            <a href={companyInfo.whatsAppLink} target="_blank" rel="noopener noreferrer">
              <WhatsAppIcon />
            </a>
            <a href={companyInfo.telegramLink} target="_blank" rel="noopener noreferrer">
              <TelegramIcon />
            </a>
            <a href={companyInfo.instagramLink} target="_blank" rel="noopener noreferrer">
              <InstagramIcon />
            </a>
          </div>
          <div className="footer__phone">شماره تماس :{companyInfo.phoneNumber}</div>
        </div>
      </div>
      <div className="footer__secondary">کلیه حقوق این سایت محفوظ و متعلق به تیس‌مد می‌باشد.</div>
    </div>
  )
}

export default Footer
