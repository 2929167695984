import React from 'react'
import { Route, Routes } from 'react-router-dom'
import CategoryPage from 'containers/CategoryPage'
import Home from 'containers/mobile/Home'
import Login from 'components/shared/forms/Login'
import ProductPage from 'containers/mobile/ProductPage'
import UserProfile from 'containers/UserProfile'
import CheckoutPage from 'containers/CheckoutPage'
import SearchResultsPage from 'components/shared/SearchResults/SearchResultsPage'
import Addresses from 'containers/Addresses'
import { PrivateRoute } from 'components/shared/PrivateRoute'
import NotFound from 'containers/NotFound'
import Products from 'containers/Products'
import Orders from '../containers/Orders'
import { Suspense } from 'react'
import AnimatedLoading from 'components/UI/AnimatedLoading'
import ProductListByHash from 'containers/ProductListByHash'
import ContactUs from 'containers/ContactUs'
import Cart from 'components/shared/Cart'
import { ROUTE_PATHS } from './RoutePaths'
function MobileRoutes() {
  return (
    <Suspense fallback={() => <AnimatedLoading />}>
      <Routes>
        <Route path={ROUTE_PATHS.home} element={<Home />} />
        <Route path={ROUTE_PATHS.product} element={<ProductPage />} />
        <Route path={ROUTE_PATHS.cart} element={<Cart />} />
        <Route path={ROUTE_PATHS.login} element={<Login />} />
        <Route
          path={ROUTE_PATHS.checkout}
          element={
            <PrivateRoute>
              <CheckoutPage />
            </PrivateRoute>
          }
        />
        <Route path={ROUTE_PATHS.hashProductList} element={<ProductListByHash />} />
        <Route path={ROUTE_PATHS.contactUs} element={<ContactUs />} />
        <Route
          path={ROUTE_PATHS.profile}
          element={
            <PrivateRoute>
              <UserProfile />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTE_PATHS.orders}
          element={
            <PrivateRoute>
              <Orders />
            </PrivateRoute>
          }
        />
        <Route path={ROUTE_PATHS.login} element={<Login />} />
        <Route path={ROUTE_PATHS.category} element={<CategoryPage />} />
        <Route path={ROUTE_PATHS.categories} element={<CategoryPage />} />
        <Route exact path={ROUTE_PATHS.search} element={<SearchResultsPage />} />
        <Route exact path={ROUTE_PATHS.productCategory} element={<Products />} />
        <Route exact path={ROUTE_PATHS.products} element={<Products />} />
        <Route
          path={ROUTE_PATHS.addresses}
          element={
            <PrivateRoute>
              <Addresses />
            </PrivateRoute>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  )
}

export default MobileRoutes
