import { useQuery } from '@tanstack/react-query'
import { ReactQueryBigStaleTime } from 'config'
import { request } from 'utils/customAxiosInterceptor'

export function useAdsBanner() {
  return useQuery({
    queryKey: ['AdvertiseBanner'],
    queryFn: async () => {
      const { data } = await request.get('AdvertiseBanner')
      return data
    },
    staleTime: ReactQueryBigStaleTime,
  })
}
