import { request } from 'utils/customAxiosInterceptor'
import { useEffect, useState } from 'react'
import { PARENT_CATEGORY_IDS } from 'utils/constants/parentCategoryIds'

/**
 * Custom hook to filter products.
 * @param {string} url - The URL to fetch products from.
 * @param {string} uniqueId - The unique ID from useParams() for specific category.
 * @param {*} params - Additional parameters for the request.
 * @returns {object} - Contains products, loading state, active filter setter, and current active filter.
 */
export const useFilterProducts = (url, uniqueId, params) => {
  const [products, setProducts] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [originalProducts, setOriginalProducts] = useState([])
  const [activeFilter, setActiveFilter] = useState(1)

  const filterProducts = (filter) => {
    switch (filter) {
      case 2:
        return products.slice().sort((a, b) => (a.create > b.create ? -1 : a.create < b.create ? 1 : 0))
      case 3:
        return products.slice().sort((a, b) => a.total_price - b.total_price)
      case 1:
      default:
        return originalProducts
    }
  }

  const fetchData = async () => {
    try {
      const { data } = await request.get(url, params)
      const isParentCategory = PARENT_CATEGORY_IDS.includes(uniqueId)

      if (uniqueId) {
        const categoryProducts = data.filter((item) => {
          const itemCategories = item.category.map((category) =>
            isParentCategory ? category.sub_category : category.id,
          )
          return itemCategories.includes(parseInt(uniqueId))
        })

        setProducts(categoryProducts)
        setOriginalProducts(categoryProducts)
      } else {
        setProducts(data)
        setOriginalProducts(data)
      }
    } catch (error) {
      console.error('Failed to fetch data:', error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [url, uniqueId, params])

  useEffect(() => {
    setProducts(filterProducts(activeFilter))
  }, [activeFilter])

  return {
    products,
    isLoading,
    setActiveFilter,
    activeFilter,
  }
}
