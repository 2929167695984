/* eslint-disable react/no-unescaped-entities */
import { useCallback, useEffect, useState } from 'react'
import ProductDetail from 'components/shared/ProductPage/ProductDetail'
import ProductImage from 'components/shared/ProductPage/ProductImage'
import { useParams } from 'react-router-dom'
import NotFound from 'containers/NotFound'
import VoteHeart from 'components/UI/VoteHeart'
import ShareIcon from 'components/UI/Icons/ShareIcon'
import ProductDescription from 'components/shared/ProductPage/ProductDescription'
import { getProductsOtherImages } from 'services/productManagementServices'
import Breadcrumb from 'components/Mobile/BreadCrumbs'
import { navigateTo } from 'routes'
import { Skeleton } from '@mui/material'
import { useGetVariants, useProduct, useProductVariantChange } from 'hooks'
import { setProduct } from '../../../utils/setProduct/setProduct'
import { Helmet } from 'react-helmet'

const styles = {
  marginTop: '16px',
  borderRadius: '16px',
}

function ProductPage() {
  const { productId } = useParams()
  const [selectedColor, setSelectedColor] = useState(null)
  const [selectedSize, setSelectedSize] = useState(null)
  const [response, setResponse] = useState({})
  const [loading, setLoading] = useState(true)
  const { data, isLoading } = useProduct(productId)
  const { data: variants } = useGetVariants()
  const getProductData = async () => {
    const productVariant = variants?.filter((item) => parseInt(item.product_variant) === parseInt(productId)) ?? null
    const productWithVariant = setProduct(data, productVariant)
    setResponse(productWithVariant)
    setLoading(false)
  }
  useEffect(() => {
    if (data) {
      getProductData()
    }
  }, [data])
  const { product, colorList, sizeList, variantError } = useProductVariantChange(
    response,
    selectedColor,
    selectedSize,
    setSelectedColor,
    setSelectedSize,
  )
  const [productImages, setProductImages] = useState([])
  const getProductImages = useCallback(async () => {
    try {
      const allImages = await getProductsOtherImages()
      const productImages = allImages.data.filter((image) => image.product === data?.id).map((image) => image.image)
      if (productImages.length) {
        setProductImages(productImages)
      }
    } catch (error) {
      console.log(error)
    }
  }, [data])
  useEffect(() => {
    getProductImages()
  }, [getProductImages])
  return (
    <>
      <Helmet>
        <title>{product ? 'tismode - ' + product.name : ''}</title>
        <meta name="product_id" content={product?.id} />
        <meta name="product_name" content={product?.name} />
        <meta property="og:image" content={product?.image} />
        <meta name="product_price" content={product?.price} />
        {/* <meta name="product_old_price" content="12234" /> */}
        <meta name="availability" content={product?.available && product?.amount > 0 ? 'instock ' : 'outofstock'} />
      </Helmet>

      <div className="product-page page container">
        {loading || isLoading ? (
          <div>
            <Skeleton variant="rectangle" height={100} sx={styles} />
            <Skeleton variant="rectangle" height={500} sx={styles} />
            <Skeleton variant="rectangle" height={80} sx={styles} />
          </div>
        ) : data ? (
          <>
            {product && (
              <>
                {product.category ? (
                  <Breadcrumb
                    crumbs={[
                      {
                        name:
                          product.category?.length > 1
                            ? `${product.category[0]?.name} و ${product.category[1]?.name}`
                            : product.category[0]?.name,
                        link: navigateTo.ProductCategory(product.category[0]?.id, product.category[0]?.name),
                      },
                      {
                        name: `/${product.name}`,
                        link: '',
                      },
                    ]}
                    isLoading={isLoading}
                  />
                ) : null}
                <div className=" product">
                  <div className="product__head__wrapper box">
                    <div className="product__stamp-area">
                      <VoteHeart />
                      <ShareIcon />
                    </div>
                    {productImages && (
                      <ProductImage product={product} images={productImages} loading={isLoading || isLoading} />
                    )}
                    <h1 className="product__head__title">{product.name}</h1>
                  </div>
                  <ProductDetail
                    product={product}
                    loading={loading || isLoading}
                    sizeList={sizeList}
                    colorList={colorList}
                    selectedColor={selectedColor}
                    selectedSize={selectedSize}
                    setSelectedColor={setSelectedColor}
                    setSelectedSize={setSelectedSize}
                    variantError={variantError}
                  />
                  <div className="product-page__footer">
                    {product?.information ? <ProductDescription product={product} /> : null}
                  </div>
                </div>
              </>
            )}
          </>
        ) : (
          <NotFound />
        )}
      </div>
    </>
  )
}

export default ProductPage
