import { Skeleton } from '@mui/material'
import { useGetCategories, useGetCustomBanners } from 'hooks'
import { useCallback } from 'react'
import { Link } from 'react-router-dom'
import { navigateTo } from 'routes'
function CenterCustomBanners() {
  const { data: banners, isLoading } = useGetCustomBanners()
  const { data: categories, isLoading: loadingCategories } = useGetCategories()
  const bannerLink = useCallback(
    (banner) => {
      switch (banner.type) {
        case 'Category':
          const categoryName = categories.find((cat) => cat.id === banner.category)?.name
          return navigateTo.ProductCategory(banner.category, categoryName)
        case 'Product':
          return navigateTo.productDetails(banner.product, banner.productName)
        case 'Hashtag':
          return navigateTo.ProductListByHashtag(banner.hashtag_name)
        default:
          break
      }
    },
    [categories],
  )

  return (
    <div className="category__grid-list container">
      {isLoading || loadingCategories
        ? Array(4)
            .fill('')
            .map((item, i) => (
              <Skeleton
                key={i}
                variant="rectangular"
                height={100}
                width={170}
                animation="wave"
                className="category__grid-list__item"
              />
            ))
        : banners
            ?.filter((banner) => banner.placement === 'Center')
            .map((banner) => (
              <div className="category__grid-list__item">
                <Link
                  to={bannerLink(banner)}
                  key={banner.id}
                  onClick={() => {
                    if (banner.type === 'Url') {
                      window.open(banner.hypertext, '_blank')
                    }
                  }}
                >
                  <div className="category__grid-list__item__image">
                    <img src={banner.center_banner} alt={banner.name} height={370} />
                  </div>
                </Link>
              </div>
            ))}
    </div>
  )
}

export default CenterCustomBanners
