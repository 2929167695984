import Layout from 'Layout'
import MobileRoutes from './MobileRoutes'
import DesktopRoutes from './DesktopRoutes'

export function AppRoutes({ size }) {
  return (
    <>
      <Layout size={size}>{size === 'lg' ? <DesktopRoutes /> : <MobileRoutes />}</Layout>
    </>
  )
}
