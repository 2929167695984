import { toast } from 'react-toastify'
import { cartHasError } from 'utils/cartFunctions'
import { toastConfig } from 'utils/toastConfig'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

export const useCart = create(
  persist(
    (set, get) => ({
      cart: [],
      /**
       *
       * @param {Object} product the actual product
       * @param {Object} data  the selected variant eg.color, size
       * @param {boolean} isInCart are we in the cart page or any other pages?
       */
      addToCart: (product, data) =>
        set((state) => {
          const { cart } = state
          const newCart = [...cart]
          const hasError = cartHasError(product, data)
          if (hasError) return cart
          const { color, size, cartAmount } = data
          const cartItem = newCart.find((item) =>
            item.product_variant
              ? item.id === product.id && item.product_variant === product.product_variant
              : item.id === product.id,
          )
          if (cartItem) {
            const amount = cartItem.cartAmount + cartAmount
            if (amount < product.amount) {
              cartItem.cartAmount = amount
              toast.success('محصول به سبد خرید اضافه شد', toastConfig)

              return {
                ...state,
                cart: newCart,
              }
            } else {
              toast.error('موجودی محصول کافی نیست', toastConfig)
              return {
                ...state,
                cart,
              }
            }
          } else {
            newCart.push({ ...product, variants: null, color, size, cartAmount })
            toast.success('محصول به سبد خرید اضافه شد', toastConfig)
            return {
              ...state,
              cart: newCart,
            }
          }
        }),
      removeFromCart: (product) =>
        set((state) => {
          const { cart } = state
          const newCart = cart.filter((item) => item.id !== product.id)
          return {
            ...state,
            cart: newCart,
          }
        }),
    }),
    {
      name: '__cart-storage', // unique name
    },
  ),
)
