import { useQuery } from '@tanstack/react-query'
import { ReactQueryBigStaleTime } from 'config'
import { request } from 'utils/customAxiosInterceptor'

export function useGetCustomBanners() {
  return useQuery({
    queryKey: ['customBanners'],
    queryFn: async () => {
      const { data } = await request.get('CustomBanner')
      return data?.filter((banner) => banner.is_enabled)
    },
    staleTime: ReactQueryBigStaleTime,
  })
}
