import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useUser } from 'store/useUser'

export { PrivateRoute }

function PrivateRoute({ children }) {
  const { user } = useUser()
  const navigate = useNavigate()
  useEffect(() => {
    if (!user?.id) {
      if (window.location.href.includes('checkout')) localStorage.setItem('redirect', 'checkout')
      navigate('/login')
    }
  }, [])

  // authorized so return child components
  return children
}
