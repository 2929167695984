import { useQuery } from '@tanstack/react-query'
import { getProductDetails } from 'services/productManagementServices'
import { request } from 'utils/customAxiosInterceptor'

export function useProduct(productId) {
  return useQuery({
    queryKey: ['product'],
    queryFn: () => request.get(getProductDetails(productId)).then((res) => res.data),
    cacheTime: 0
  })
}
