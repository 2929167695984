import React, { useState } from 'react'
import EditIcon from 'components/UI/Icons/EditIcon'
import LocationIcon from 'components/UI/Icons/LocationIcon'
import DrawerModal from 'components/UI/DrawerModal'
import AddressForm from 'components/shared/forms/AddressForm'
function AddressListItem({ address, user, getAddresses }) {
  const [open, setOpen] = useState(false)

  return (
    <>
      <div className="addresses__list__item checkout-box-item">
        <div>
          <LocationIcon />
        </div>
        <div className="addresses__list__item__address">
          <h3>آدرس:</h3>
          <p className="addresses__list__item__text">
            {address.province},{address.city},{address.address}
          </p>
          <span className="addresses__list__item__edit" onClick={() => setOpen(true)}>
            <span>
              <EditIcon />
            </span>
            <span>ویرایش</span>
          </span>
        </div>
      </div>
      {open && (
        <DrawerModal open={open} setOpen={setOpen} title="افزودن آدرس">
          <AddressForm
            setOpenForm={setOpen}
            selectedAddress={address}
            user={user}
            mode="edit"
            location="profile"
            getAddresses={getAddresses}
          />
        </DrawerModal>
      )}
    </>
  )
}

export default AddressListItem
