import ProductCard from 'components/shared/ProductCard'
import NotFound from 'containers/NotFound'
import { useGetProducts } from 'hooks'
import React from 'react'
import { useParams } from 'react-router-dom'

function ProductListByHash() {
  const { hash } = useParams()
  const { isLoading, products } = useGetProducts()

  return (
    <div className="hash-products page container">
      {products.length ? (
        <div className="products__list">
          {products.map((product) => (
            <ProductCard product={product} isLoading={isLoading} key={product.id} />
          ))}
        </div>
      ) : (
        <NotFound />
      )}
    </div>
  )
}

export default ProductListByHash
