import priceImage from 'assets/images/toman.png'
import { useWindowSize } from 'hooks'
import { useNavigate } from 'react-router-dom'
import { convertToPersian } from 'utils/convertNumbers'
function CartInfos({ cart }) {
  const navigate = useNavigate()
  const { windowSize } = useWindowSize()
  return (
    <div className="cart__payment__method">
      {cart.length ? (
        <>
          {windowSize === 'lg' ? (
            <div className="cart__total">
              <div className="cart__total__section">
                <div>جمع تخفیفات </div>
                <div>{cart.reduce((a, b) => a + b.discount_price, 0)}</div>
              </div>
              <div className="cart__total__section">
                <div>قیمت کالا‌ها</div>
                <div>{convertToPersian(cart.reduce((a, b) => a + b.total_price * b.cartAmount, 0))}</div>
              </div>
              <button onClick={() => navigate('/checkout')} className="cart__total__button">
                ادامه
              </button>
            </div>
          ) : (
            <button className="cart__payment__method__continue" onClick={() => navigate('/checkout')}>
              {' '}
              <div className="cart__payment__method__price">
                {cart.length > 0
                  ? convertToPersian(cart.reduce((a, b) => a + b.total_price * b.cartAmount, 0))
                  : cart[0]?.total_price}
                <img src={priceImage} alt="" />
              </div>
              <div className="cart__payment__method__text">ادامه خرید</div>
            </button>
          )}
        </>
      ) : null}
    </div>
  )
}

export default CartInfos
