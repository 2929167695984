import { useQuery } from '@tanstack/react-query'
import { extraStaleTime } from 'config'

export function useProvinceList() {
  return useQuery({
    queryKey: ['provinces'],
    queryFn: async () => {
      const response = await fetch('https://iran-locations-api.vercel.app/api/v1/states')
      const data = await response.json()
      return data.map((province) => {
        return {
          value: province.id,
          label: province.name,
        }
      })
    },
    staleTime: extraStaleTime,
  })
}
