import AddressListItem from 'components/Mobile/AddressListItem'
import React from 'react'
import { request } from 'utils/customAxiosInterceptor'
import Skeleton from '@mui/material/Skeleton'
import DrawerModal from 'components/UI/DrawerModal'
import AddressForm from 'components/shared/forms/AddressForm'
import AddIcon from 'components/UI/Icons/AddIcon'
import { useUser } from 'store/useUser'

function Addresses() {
  const { user } = useUser()
  const [addresses, setAddresses] = React.useState([])
  const [loading, setLoading] = React.useState(true)
  const [open, setOpen] = React.useState(false)

  const getAddresses = async () => {
    const response = await request.get(`Addresslist?search=${user.mobile}`)
    const addresses = response.data?.reverse()
    setAddresses(addresses)
    setLoading(false)
  }
  React.useEffect(() => {
    if (user?.id) {
      getAddresses()
    }
  }, [])
  if (!user?.id) return <div>Please login to see your addresses</div>
  return (
    <div className="addresses container page">
      <h3>آدرس های شما</h3>
      <div className="addresses__list">
        {loading ? (
          <Skeleton variant="rectangular" animation="wave" height={80} />
        ) : (
          <>
            <div onClick={() => setOpen(true)} className="addresses__list__item">
              <AddIcon />
              <p className="addresses__add-new__text">افزودن آدرس </p>
            </div>
            {open && (
              <DrawerModal open={open} setOpen={setOpen} title="افزودن آدرس">
                <AddressForm
                  setOpenForm={setOpen}
                  user={user}
                  mode="add"
                  location="profile"
                  getAddresses={getAddresses}
                />
              </DrawerModal>
            )}
            {addresses.map((address, index) => (
              <AddressListItem address={address} key={index} user={user} getAddresses={getAddresses} />
            ))}
          </>
        )}
      </div>
    </div>
  )
}

export default Addresses
