export const productVariantPickerStyles = {
  container: (base) => ({
    ...base,
    width: '100%',
    minWidth: '100px',
  }),
  control: (base, state) => ({
    ...base,
    width: '100% ',
    fontSize: '14px',
    zIndex: 999,
    background: '#E0E0E0',
    borderRadius: '8px',
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: 'black',
      display: 'flex !important',
      justifyContent: 'center !important',
    }
  },
  dropdownIndicator: (provided) => ({
    ...provided,
    svg: {
      fill: '#292D32',
    },
    padding: '0',
  }),
  //indicates the option that is selected
  singleValue: (styles, state) => ({
    ...styles,
    width: '100px',

    paddingRight: '8px',
    display: 'flex !important',
    justifyContent: 'center !important',
  }),
}
