import { toast } from 'react-toastify'
import { toastConfig } from './toastConfig'

export const cartHasError = (product, data) => {
  console.log(product.sizes?.length, data);
  if (product.colors?.length && !data.selectedColor) {
    toast.error('لطفا رنگ محصول را انتخاب کنید', toastConfig)
    return true
  }
  if (product.sizes?.length && !data.selectedSize) {
    toast.error('لطفا سایز محصول را انتخاب کنید', toastConfig)
    return true
  }
  if (product.sizes?.length && data.selectedSize  && product.colors?.length && data.selectedColor !== null) {
    return false
  }
}
