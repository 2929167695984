import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper'
import { Skeleton } from '@mui/material'
import NextArrow from 'components/UI/Icons/nextArrow'

function Swipper({ loading, title, ...props }) {
  const navigationPrevRef = React.useRef(null)
  const navigationNextRef = React.useRef(null)
  return (
    <div className="swiper-wrapper">
      <Swiper
        spaceBetween={props.spaceBetween || 12}
        // loopedSlides={120}
        observer
        observeParents
        parallax
        loop={true}
        slidesPerView={2}
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        onBeforeInit={(swiper) => {
          swiper.params.navigation.prevEl = navigationPrevRef.current
          swiper.params.navigation.nextEl = navigationNextRef.current
        }}
        modules={[Navigation]}
        breakpoints={{
          600: {
            slidesPerView: 2,
          },

          800: {
            slidesPerView: 2,
            spaceBetween: 12,
          },
          1000: {
            slidesPerView: 4,
            spaceBetween: 12,
          },
        }}
      >
        {loading
          ? new Array(4).fill(true).map((item, index) => (
              <SwiperSlide key={index}>
                <Skeleton variant="rectangular" height={184} animation="wave" />
              </SwiperSlide>
            ))
          : props.children}
      </Swiper>
      {props.children?.length ? (
        <>
          <div className="nextArrow" ref={navigationNextRef}>
            <NextArrow />
          </div>
          <div className="prevArrow" ref={navigationPrevRef}>
            <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M4.82023 8.91311L4.81709 8.91L1.04171 5.20085C0.98329 5.12085 0.966675 5.05249 0.966675 5C0.966675 4.94751 0.98329 4.87914 1.04171 4.79915L4.81709 1.09L4.8171 1.09001L4.82023 1.08689C4.85738 1.04974 4.87466 1.04176 4.87808 1.04024C4.87812 1.04022 4.87817 1.0402 4.87823 1.04017C4.88016 1.03928 4.89303 1.03333 4.93334 1.03333C4.97365 1.03333 4.98653 1.03928 4.98845 1.04017C4.98851 1.0402 4.98857 1.04022 4.98861 1.04024C4.99202 1.04176 5.00931 1.04974 5.04646 1.08689C5.08901 1.12944 5.10001 1.17011 5.10001 1.2C5.10001 1.22989 5.08901 1.27056 5.04646 1.31311L5.04644 1.31309L5.0429 1.3167L1.77623 4.65003L1.43327 5L1.77623 5.34996L5.0429 8.6833L5.04288 8.68331L5.04646 8.68689C5.08901 8.72944 5.10001 8.77011 5.10001 8.8C5.10001 8.82988 5.08901 8.87055 5.04646 8.91311C5.0039 8.95567 4.96323 8.96667 4.93334 8.96667C4.90346 8.96667 4.86279 8.95567 4.82023 8.91311Z"
                fill="black"
                stroke="#4F4F4F"
              />
            </svg>
          </div>
        </>
      ) : null}
    </div>
  )
}

export default Swipper
