import Appbar from 'components/Mobile/Appbar'
import React from 'react'
import { useLocation } from 'react-router-dom'
import Footer from './footer'
import Header from './Header'

function Layout({ size, children }) {
  return (
    <>
      <Header size={size} />
      {children}
      <footer>
        <Footer size={size} />
      </footer>

      <Appbar />
    </>
  )
}

export default Layout
