import { Skeleton } from '@mui/material'
import SliderCarousel from 'components/shared/SliderCarousel'
import { useHeroBanner } from 'hooks'
import React from 'react'
import CollectionsTopBanner from '../CollectionsTopBanner'

function HeroBanner() {
  const { data, isLoading } = useHeroBanner()
  return (
    <div className="herobanner none-vertical-space">
      {isLoading && <Skeleton variant="rectangular" width={'100%'} height={500} />}
      {data && <SliderCarousel data={data} isLoading={isLoading} />}
      <CollectionsTopBanner />
    </div>
  )
}

export default HeroBanner
