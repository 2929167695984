import React, { Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import { ROUTE_PATHS } from './RoutePaths'
import AnimatedLoading from 'components/UI/AnimatedLoading'
import NotFound from 'containers/NotFound'
import Home from 'containers/web/Home'
import Product from 'containers/web/Product'
import Products from 'containers/web/Products'
import Cart from 'components/shared/Cart'
import { PrivateRoute } from 'components/shared/PrivateRoute'
import UserProfile from 'containers/UserProfile'
import Login from 'components/shared/forms/Login'
import CheckoutPage from 'containers/CheckoutPage'
import Addresses from 'containers/Addresses'
import ContactUs from 'containers/ContactUs'
import CategoryPage from 'containers/CategoryPage'

function DesktopRoutes() {
  return (
    <Suspense fallback={() => <AnimatedLoading />}>
      <Routes>
        <Route path={ROUTE_PATHS.home} element={<Home />} />
        <Route path={ROUTE_PATHS.product} element={<Product />} />
        <Route exact path={ROUTE_PATHS.productCategory} element={<Products />} />
        <Route path={ROUTE_PATHS.cart} element={<Cart />} />
        <Route path={ROUTE_PATHS.contactUs} element={<ContactUs />} />
        <Route path={ROUTE_PATHS.category} element={<CategoryPage />} />
        <Route path={ROUTE_PATHS.categories} element={<CategoryPage />} />

        <Route
          path={ROUTE_PATHS.checkout}
          element={
            <PrivateRoute>
              <CheckoutPage />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTE_PATHS.addresses}
          element={
            <PrivateRoute>
              <Addresses />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTE_PATHS.profile}
          element={
            <PrivateRoute>
              <UserProfile />
            </PrivateRoute>
          }
        />
        <Route path={ROUTE_PATHS.login} element={<Login />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  )
}

export default DesktopRoutes
