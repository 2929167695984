import React, { useMemo } from 'react'
import { useEffect } from 'react'
import SubCategories from 'components/Mobile/SubCategories'
import { useParams } from 'react-router-dom'
import AnimatedLoading from 'components/UI/AnimatedLoading'
import { useGetCategories } from 'hooks'
import { PARENT_CATEGORY_IDS } from 'utils/constants/parentCategoryIds'
function CategoryPage() {
  const { categoryId } = useParams()
  const [selectedCategory, setSelectedCategory] = React.useState({
    name: '',
    id: parseInt(categoryId),
  })
  const [mainCategories, setMainCategories] = React.useState([])
  const { data: categories, isLoading } = useGetCategories()

  useMemo(() => {
    setMainCategories(categories?.filter((category) => PARENT_CATEGORY_IDS.includes(category.id.toString())))
  }, [categories])

  useEffect(() => {
    if (!categoryId) {
      setSelectedCategory({
        name: categories?.[0]?.name,
        id: categories?.[0]?.id,
      })
    }
  }, [categoryId, categories])
  if (isLoading) {
    return <AnimatedLoading isFullPage={true} background={'#F8043F'} />
  }
  return (
    <>
      <div className="category__page container page">
        <div className="category__container">
          <div className="category__main-cats">
            {mainCategories?.map((category, index) => (
              <div
                className={`category__main-item ${
                  selectedCategory.id === category.id ? `category__main-item--selected-${index}` : ''
                }`}
                key={category.id}
                onClick={() =>
                  setSelectedCategory({
                    id: category.id,
                    name: category.name,
                  })
                }
              >
                <div className="category__main-item__name">{category.name}</div>
              </div>
            ))}
          </div>

          <SubCategories selectedCategory={selectedCategory} categories={categories} />
        </div>
      </div>
    </>
  )
}

export default CategoryPage
