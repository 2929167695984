import { useQuery } from '@tanstack/react-query'
import { ReactQueryBigStaleTime } from 'config'
import { request } from 'utils/customAxiosInterceptor'

export async function useAddressList() {
  const user = JSON.parse(localStorage.getItem('__user-storage'))?.state.user
  console.log(user)
  return useQuery({
    queryKey: ['AddressList'],
    queryFn: async () => {
      const { data } = await request.get(`Addresslist?search=${user.mobile}`)
      return data
    },
    staleTime: ReactQueryBigStaleTime,
  })
}
