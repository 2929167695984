/* eslint-disable no-undef */
let BASE_URL_API = 'https://backend.tismod.com'
let PRODUCTION_MODE = process.env.NODE_ENV === 'production'
const extraStaleTime = 30 * (60 * 1000) // 1 hour
const BigStaleTime = 20 * (60 * 1000) //20 minutes
const tempStaleTime = 5 *1000
// if (process.env.NODE_ENV === 'production') {
//   BASE_URL_API = window.location.origin
//   PRODUCTION_MODE = false
// } else {
//   BASE_URL_API = 'https://tismod.com'
// }

export { PRODUCTION_MODE, BASE_URL_API , BigStaleTime as ReactQueryBigStaleTime, tempStaleTime, extraStaleTime }

// function renderTodo(todo) {
//   localStorage.setItem('todoItems', JSON.stringify(todoItems));

//   const list = document.querySelector('.js-todo-list');
//   const item = document.querySelector(`[data-key='${todo.id}']`);
  
//   if (todo.deleted) {
//     item.remove();
//     if (todoItems.length === 0) list.innerHTML = '';
//     return
//   }

//   const isChecked = todo.checked ? 'done': '';
//   const node = document.createElement("li");
//   node.setAttribute('class', `todo-item ${isChecked}`);
//   node.setAttribute('data-key', todo.id);
//   node.innerHTML = `
//     <input id="${todo.id}" type="checkbox"/>
//     <label for="${todo.id}" class="tick js-tick"></label>
//     <span>${todo.text}</span>
//     <button class="delete-todo js-delete-todo">
//     <svg><use href="#delete-icon"></use></svg>
//     </button>
//   `;

//   if (item) {
//     list.replaceChild(node, item);
//   } else {
//     list.append(node);
//   }
// }

// function addTodo(text) {
//   const todo = {
//     text,
//     checked: false,
//     id: Date.now(),
//   };

//   todoItems.push(todo);
//   renderTodo(todo);
// }

// function toggleDone(key) {
//   const index = todoItems.findIndex(item => item.id === Number(key));
//   todoItems[index].checked = !todoItems[index].checked;
//   renderTodo(todoItems[index]);
// }

// function deleteTodo(key) {
//   const index = todoItems.findIndex(item => item.id === Number(key));
//   const todo = {
//     deleted: true,
//     ...todoItems[index]
//   };
//   todoItems = todoItems.filter(item => item.id !== Number(key));
//   renderTodo(todo);
// }

// const form = document.querySelector('.js-form');
// form.addEventListener('submit', event => {
//   event.preventDefault();
//   const input = document.querySelector('.js-todo-input');

//   const text = input.value.trim();
//   if (text !== '') {
//     addTodo(text);
//     input.value = '';
//     input.focus();
//   }
// });

// const list = document.querySelector('.js-todo-list');
// list.addEventListener('click', event => {
//   if (event.target.classList.contains('js-tick')) {
//     const itemKey = event.target.parentElement.dataset.key;
//     toggleDone(itemKey);
//   }
  
//   if (event.target.classList.contains('js-delete-todo')) {
//     const itemKey = event.target.parentElement.dataset.key;
//     deleteTodo(itemKey);
//   }
// });

// document.addEventListener('DOMContentLoaded', () => {
//   const ref = localStorage.getItem('todoItems');
//   if (ref) {
//     todoItems = JSON.parse(ref);
//     todoItems.forEach(t => {
//       renderTodo(t);
//     });
//   }
// });