import React from 'react'
import CartItems from './CartItems'
import { useCart } from 'store'

function Cart() {
  const { cart } = useCart()

  return (
    <div className="cart container">
      <div className="cart__title">
        <p>سبد خرید شما</p>
      </div>
      <div className="cart__content">
        {!cart || !cart.length ? (
          <div className="cart__empty">سبد خرید شما خالی است!</div>
        ) : (
          <>
            <div className="cart__items">
              <CartItems cartItems={cart} />
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default Cart
