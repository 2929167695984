import React from 'react'

function NextArrow() {
  return (
    <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.23157 1.1121L1.23157 1.11211L1.23333 1.11387L5.00667 4.88053L5.35991 4.52667L5.02087 4.89417C5.03779 4.90977 5.05129 4.92872 5.06053 4.9498C5.06976 4.97088 5.07453 4.99365 5.07453 5.01667C5.07453 5.03968 5.06976 5.06245 5.06053 5.08353C5.05129 5.10462 5.03779 5.12356 5.02087 5.13917L5.01364 5.14584L5.00667 5.1528L1.23333 8.91947L1.23331 8.91945L1.2295 8.92334C1.21406 8.93909 1.19565 8.95161 1.17534 8.9602C1.15567 8.96851 1.13459 8.97296 1.11324 8.97331C1.09189 8.97296 1.07081 8.96851 1.05114 8.9602C1.03083 8.95161 1.01242 8.93909 0.996982 8.92334L0.996991 8.92333L0.99451 8.92083C0.963469 8.88961 0.946045 8.84736 0.946045 8.80333C0.946045 8.75944 0.963356 8.71733 0.99421 8.68614C0.99431 8.68603 0.99441 8.68593 0.99451 8.68583L4.29346 5.38689L4.64524 5.03511L4.29523 4.68156L0.995232 1.34823L0.995233 1.34823L0.99451 1.3475C0.963469 1.31627 0.946045 1.27403 0.946045 1.23C0.946045 1.18597 0.963469 1.14373 0.99451 1.1125L0.994907 1.1121C1.0104 1.09648 1.02883 1.08408 1.04914 1.07562C1.06945 1.06716 1.09124 1.0628 1.11324 1.0628C1.13524 1.0628 1.15703 1.06716 1.17734 1.07562C1.19765 1.08408 1.21608 1.09648 1.23157 1.1121Z"
        fill="black"
        stroke="#4F4F4F"
      />
    </svg>
  )
}

export default NextArrow
